<template lang="pug">
  Section.cy-selectable-options-section(
    :title="$t('plan_settings.basic_settings.sections.selectable_options.section_name')"
  )
    .block
      .section-content-header
        span.text {{ isSet ? $t("plan_settings.basic_settings.set") : $t("plan_settings.basic_settings.not_set") }}
        SwitchComponent(
          :is-hidden="hidden"
          @click="toggleTable"
        )
      OptionsTable.m-t-8(
        v-if="!hidden"
        :car-options="object"
      )
</template>

<script>
  import { findKey } from "lodash-es"
  import { STANDARD, SELECTABLE } from "@/config/constants"

  export default {
    props: {
      object: Object
    },

    data() {
      return {
        hidden: true
      }
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      OptionsTable: () => import("./OptionsTable"),
      SwitchComponent: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Shared/SwitchComponent")
    },

    computed: {
      isSet() {
        return (
          findKey(this.object.$each.$iter, ({ selectability_type }) => {
            return [STANDARD, SELECTABLE].includes(selectability_type.$model)
          }) !== undefined
        )
      }
    },

    watch: {
      object: {
        handler(newObject) {
          if (newObject.$invalid) {
            this.hidden = false
          }
        },
        deep: true
      }
    },

    methods: {
      toggleTable() {
        this.object.$reset()
        this.$nextTick(() => {
          this.hidden = !this.hidden
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .block
    +block

    .section-content-header
      +section-content-header
</style>
